enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN_PASSCODE = 'loginPasscode',
  REASEND_LOGIN_PASSCODE = 'resendLoginPasscode',
  CHECK_LOGIN_PASSCODE = 'checkLoginPasscode',
  CHECK_TOKEN = "checkToken",
  LOGIN = "login",
  GOOGLE_2FA = "google2fa",
  PASSWORD_REAST = "passwordRest",
  EMAIL_2FA = "email2fa",
  EMAIL_RESEND_2FA = "EmailResend2fa",
  PHONE_2FA = "phone2fa",
  PHONE_RESEND_2FA = "phoneResend2fa",
  SMS_2FA = "sms2fa",
  PHONE_VERIFIED = "google2fa",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_AUTH_GOOGLE = "setAuthGoogle",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
}

export { Actions, Mutations };
