import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    api_token: string;
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();
    status;

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    /**
     *@return array
     *
     */
    get getLoginStatus() {
        return this.status;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }
    
    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;
        this.user = user;
        this.errors = {};
        JwtService.saveToken(user.data.response.token);
    }
    @Mutation
    [Mutations.SET_AUTH_GOOGLE](token) {
        this.isAuthenticated = true;
        JwtService.saveToken(token);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
    }

    @Action
    [Actions.GOOGLE_2FA](value) {
        value.id=localStorage.getItem('user_id')
        return ApiService.post('google-auth-check', value).then((data) => {
            this.context.commit(Mutations.SET_AUTH, data.data);
            return {
                error: false,
            }
        }).catch((response) => {
            if (response){
                return {
                    error: true,
                    message: response.response.data.message
                }
            }
        })
    }

    @Action
    [Actions.EMAIL_2FA](value) {
        value.id=localStorage.getItem('user_id')
        return ApiService.post('two-factory', value).then((data) => {
            this.context.commit(Mutations.SET_AUTH, data.data);
            return {
                error: false,
            }
        }).catch((response) => {
            // this.context.commit(Mutations.SET_ERROR, response.data.message);
            if (response){
                return {
                    error: true,
                    message: response.response.data.message
                }
            }
        })
    }

    @Action
    async [Actions.CHECK_TOKEN](value) {
        // let data_response= {data:{response:{token:value}}}
        await JwtService.saveToken(value);
        return await ApiService.post('verify_token', value).then(({data}) => {
            this.context.commit(Mutations.SET_AUTH, data);
            return {
                error: false
            }
        }).catch(({response}) => {
                if (response) {
                    // JwtService.destroyToken();
                    return {
                        error: true
                    }
                }
            }
        )
    }

    @Action
    [Actions.EMAIL_RESEND_2FA]() {
        const value = new Object()
        value['id'] = localStorage.getItem('user_id')
        return ApiService.post('resend-two-factory', value).then(({data})=>{
            return data
        }).catch(({response}) => {
            return response
        })
    }

    @Action
    [Actions.SMS_2FA](value) {
        value.id=localStorage.getItem('user_id')
        return ApiService.post('phone-number', value).then((data) => {
            this.context.commit(Mutations.SET_AUTH, data.data);
            return {
                error: false,
            }
        }).catch((response) => {
            // this.context.commit(Mutations.SET_ERROR, response.data.message);
            if (response){
                return {
                    error: true,
                    message: response.response.data.message
                }
            }
        })
    }

    @Action
    [Actions.PHONE_RESEND_2FA](value) {
        value.num = {'phone_number': localStorage.getItem('phone_number')}
        return ApiService.post('phone_number', value).then().catch(response => {
            this.context.commit(Mutations.SET_ERROR, response.data.message);
        })
    }

    @Action
    [Actions.LOGIN](credentials) {
        return ApiService.post("login", credentials)
            .then(({data}) => {
                if (data.data.status == "none") {
                    this.context.commit(Mutations.SET_AUTH, data);
                } else {
                    window.localStorage.setItem('user_id', data.data.response.user_id)
                }
                return {
                    status: data.data.status,
                };

            })
            .catch(({response}) => {
                if (response) {
                    this.context.commit(Mutations.SET_ERROR, response.data.message);
                    return {
                        status : false,
                        message : response.data.message
                    }
                }
            });
    }

    @Action
    [Actions.LOGIN_PASSCODE](value){
        return ApiService.post('passcode/email',value).then(({data})=>{
            localStorage.setItem('email',value.email)
            return data
        }).catch(({response})=>{
            return response
        })
    }

    @Action
    [Actions.CHECK_LOGIN_PASSCODE](value){
        value.email=localStorage.getItem('email')
        return ApiService.post('passcode/email/check',value).then(({data})=>{
            localStorage.removeItem('email')
            if (data.data.status == "none") {
                this.context.commit(Mutations.SET_AUTH, data);
            } else {
                window.localStorage.setItem('user_id', data.data.response.id)
            }
            return data
        }).catch(({response})=>{
            return response
        })
    }

    @Action
    [Actions.REASEND_LOGIN_PASSCODE](){
        const  value= new Object();
        value['email']=localStorage.getItem('email')
        return ApiService.post('passcode/email',value).then(({data})=>{
            return data
        }).catch(({response})=>{
            return response
        })
    }
    
    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }


    @Action
    [Actions.PASSWORD_REAST](value) {
        
        return ApiService.post('password-reset', value).then().catch((response) => {
                if (response) {
                    this.context.commit(Mutations.SET_ERROR, response.data.message);
                }
            }
        )
    }

    @Action
    [Actions.REGISTER](credentials) {
        return ApiService.post("register", credentials)
            .then(({data}) => {
                this.context.commit(Mutations.SET_AUTH, data);
            })
            .catch(({response}) => {
                if (response) {
                    this.context.commit(Mutations.SET_ERROR, response.data.message);
                }
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD](value) {
        return ApiService.post('forget', value).then((data) => {
            return {
                error: false,
            }
        }).catch((response) => {
            if (response){
                this.context.commit(Mutations.SET_ERROR, response.response.data.message)
                return {
                    error: true,
                    message: response.response.data.message
                }
            }
        })
    }


    @Action
    [Actions.VERIFY_AUTH](payload) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post("verify_token", payload)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                })
                .catch(({response}) => {
                    if (response) {
                        this.context.commit(Mutations.SET_ERROR, response.data.message);
                        this.context.commit(Mutations.PURGE_AUTH);
                    }
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }
}
